<template>
  <v-card
    elevation="0"
    color="grey darken-2"
  >
    <v-responsive :aspect-ratio="4">
      <div
        style="height: 100%;"
        class="d-flex flex-column justify-center px-4"
      >
        <div class="d-flex align-center">
          <div>
            <v-icon
              v-if="icon"
              color="primary lighten-4"
              size="32"
            >
              {{ icon }}
            </v-icon>
            <div
              class="text-truncate"
              style="max-width: 100px"
            >
              {{ label }}
            </div>
          </div>

          <v-spacer/>
          <div class="d-flex align-baseline">
            <div class="text-h4 font-weight-bold">
              {{ info }}
            </div>
            <div class="text-caption">
              {{ append }}
            </div>
          </div>
        </div>
      </div>
    </v-responsive>
  </v-card>
</template>

<script>

  export default {
    name: 'DashboardKeyInfoCard',
    props: {
      label: {},
      icon: {},
      info: {},
      append: {},
    },
  }
</script>

<style scoped>

</style>
