<template>
  <v-dialog
    v-model="timePickerDialog"
    max-width="400px"
  >
    <template #activator="{on,attr}">
      <v-card
        :color="color"
        v-bind="attr"
        class="mt-2 d-flex align-center pa-4 px-4"
        elevation="0"
        :class="cardClass"
        v-on="on"
      >
        {{ $t('dayOfWeek') }}：{{ realValue | niceLabel }}
        <v-spacer/>
        <v-icon>
          mdi-calendar-sync-outline
        </v-icon>
      </v-card>
    </template>
    <v-card
      class="pa-6"
      color="#f6f6f6"
    >
      <date-range-picker v-model="realValue"/>
      <v-btn
        class="mt-2"
        x-large
        rounded
        elevation="0"
        block
        color="primary lighten-4 black--text"
        @click="timePickerDialog=false"
      >
        {{ $t('confirm') }}
        <v-icon
          right
        >
          mdi-check
        </v-icon>
      </v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
  import DateRangePicker from '@/composable/DateRangePicker.vue'
  import { today } from '@/common/Repository/DateRepository'

  export default {
    name: 'DatePickerInput',
    components: {
      DateRangePicker,
    },
    props: {
      color: {},
      value: {},
      cardClass: {},
    },
    data: function () {
      return {

        realValue: [today, today],
        timePickerDialog: false,
      }
    },
    watch: {
      value: {
        immediate: true,
        handler (val) {
          this.realValue = val
        },
      },
      realValue (val, old) {
        if (val !== old) {
          this.$emit('input', val)
        }
      },
    },
  }
</script>

<style scoped>

</style>
