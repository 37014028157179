<template>
  <v-dialog
    v-model="realValue"
    max-width="1000px"
  >
    <v-card
      class="pa-6"
      color="#f6f6f6"
    >
      <back-button
        icon="mdi-close"
        @close-event="closeDialog"
      />
      <div
        class="text-body-1"
        style="display: grid;grid-template-columns: 1fr 0.7fr;grid-gap: 24px"
      >
        <div
          style="max-height: 70vh;overflow-y: scroll"
        >
          <div class="text-h4 px-1 mt-4">
            <b>{{ $t('SalesPreview') }}</b>
          </div>
          <div
            class="mt-8"
            style="display: grid;grid-template-columns: repeat(2,minmax(0,1fr));grid-gap: 16px;"
          >
            <div
              class="text-subtitle-1"
              style="grid-column: span 2"
            >
              {{ $t('SumItUp') }}
            </div>
            <summary-card
              :label="$t('sales')"
              :value="totalPaid|priceDisplay"
              color="green darken-4"
              dark
              icon=" mdi-store"
            />
            <summary-card
              :label="$t('TotalOrderQuantity')"
              :value="totalOrder"
              color="green lighten-5"
              icon=" mdi-food"
            />
            <summary-card
              :label="$t('Groceries')"
              :value="dishTotal|priceDisplay"
              color="white"
              icon=" mdi-food-apple"
            />
            <summary-card
              :label="$t('Alcohol')"
              :value="drinkTotal|priceDisplay"
              color="white"
              icon=" mdi-glass-mug-variant"
            />
            <div
              class="text-subtitle-1"
              style="grid-column: span 2"
            >
              {{ $t('billInfo') }}
            </div>
            <v-card
              class="pa-4"
              elevation="0"
            >
              <div class="text-h3 font-weight-medium">
                {{ $t('SumUp') }}
              </div>
              <div class="mt-6 d-block overflow-hidden">
                <div class="d-flex text-body-2">
                  {{ $t('TotalOf') }}
                  <v-spacer/>
                  {{ billContent?.total | priceDisplay }}
                </div>
                <div class="d-flex text-body-2">
                  <div class="text-truncate">
                    {{ $t('NetWorth') }}
                  </div>
                  <v-spacer/>
                  <div class="text-no-wrap">
                    {{ billContent?.fTotalTe }}/{{ billContent?.fTotalTax | priceDisplay }}
                  </div>
                </div>
              </div>
            </v-card>
            <template v-for="(type, index) in taxGroupInfo">
              <v-card
                :key="type.taxRatePercentage+'-'+index"
                class="pa-4"
                elevation="0"
              >
                <div class="text-h3 font-weight-medium">
                  {{ type.taxRatePercentage }}%
                </div>
                <div class="mt-6 d-block overflow-hidden">
                  <div class="d-flex text-body-2">
                    <div class="text-truncate">
                      {{ $t('TotalOf') }}
                    </div>
                    <v-spacer/>
                    <div class="text-no-wrap">
                      {{ type.groupTotal | priceDisplay }}
                    </div>
                  </div>
                  <div class="d-flex text-body-2">
                    <div class="text-truncate">
                      {{ $t('NetWorth') }}
                    </div>
                    <v-spacer/>
                    <div class="text-no-wrap">
                      {{ type.nettoumsatz }}/{{ type.umsatzsteuer | priceDisplay }}
                    </div>
                  </div>
                </div>
              </v-card>
            </template>
          </div>
        </div>

        <template v-if="!isPrint">
          <v-card
            class="pa-4 d-flex flex-column"
            color="white"
            elevation="0"
          >
            <div class="text-h4">
              <b>{{ $t('DatePrintOptions') }}</b>
            </div>
            <date-picker-input
              v-model="localDates"
              card-class="mt-4"
              color="#f6f5f6"
            />
            <v-card
              class="mt-2 px-4 d-flex flex-column"
              color="#f6f5f6"
              elevation="0"
            >
              <div
                v-if="localDates[0] !== localDates[1]"
                class="d-flex align-center justify-center"
              >
                <div>{{ $t('CombinedDocuments') }}</div>
                <v-spacer/>
                <v-switch
                  v-model="mergeBills"
                  dense
                  style="margin-right: -25px"
                />
              </div>
            </v-card>
            <v-card
              class="mt-2 px-4 d-flex flex-column"
              color="#f6f5f6"
              elevation="0"
            >
              <div class="d-flex align-center justify-center">
                <div>{{ $t('PasswordResetToEmail') }}</div>
                <v-spacer/>
                <v-switch
                  v-model="sendEmail"
                  dense
                  style="margin-right: -25px"
                  @click="checkZBonEmail"
                />
              </div>
            </v-card>
            <div
              class="text-caption px-4 mt-2"
              v-html="showDetailPrintMessage"
            />
            <div class="d-flex text-body-1">
              <v-spacer/>
              <div>{{ $t('TotalOf') }} <span class="font-weight-bold">{{ totalBillsCount }}</span> {{ $t('Pice') }}</div>
            </div>
            <v-spacer/>
            <div style="display: grid;grid-template-columns: repeat(5,minmax(0,1fr));grid-gap: 12px">
              <tab-button
                :active="totalSales"
                :color="totalSales ? '#F48FE2' : '#ffffff'"
                :name="$t('SalesStatistics')"
                icon="mdi-chart-line"
                @click="totalSales = !totalSales"
              />
              <tab-button
                :active="totalTime"
                :color="totalTime ? '#F48FE2' : '#ffffff'"
                :name="$t('TotalPeriod')"
                icon="mdi-clock-time-eight-outline"
                @click="totalTime = !totalTime"
              />
              <tab-button
                :active="deliveryList"
                :color="deliveryList ? '#F48FE2' : '#ffffff'"
                :name="$t('DeliveryList')"
                icon="mdi-truck"
                @click="deliveryList = !deliveryList"
              />
              <tab-button
                :active="activeXBon"
                :color="activeXBon ? '#F48FE2' : '#ffffff'"
                icon="mdi-receipt-outline"
                name="XBon"
                @click="selectXBon"
              />
              <tab-button
                :active="activeZBon"
                :color="activeZBon ? '#F48FE2' : '#ffffff'"
                :disabled="ZBonList.length !== 0"
                icon="mdi-calendar-text-outline"
                name="ZBon"
                @click="selectZBon"
              />
            </div>
            <div>
              <v-btn
                v-if="sendEmail"
                :disabled="!disableSendEmail"
                block
                class="mt-2"
                color="blue lighten-4 black--text"
                elevation="0"
                large
                @click="selectSendEmail"
              >
                <div>{{ $t('OnlySentToMail') }}</div>
                <v-icon right>
                  mdi-send
                </v-icon>
              </v-btn>
              <v-btn
                :disabled="!disablePrint"
                block
                class="mt-2"
                color="primary lighten-4 black--text"
                elevation="0"
                large
                @click="selectPrintType"
              >
                <div>{{ $t('IncomingPrint') }}</div>
                <v-icon right>
                  mdi-send
                </v-icon>
              </v-btn>
            </div>
          </v-card>
        </template>
        <template v-else>
          <template v-if="!printEnd">
            <template v-if="showPrintWarn">
              <v-card
                class="d-flex align-center justify-center flex-column"
                color="white"
                elevation="0"
              >
                <v-icon
                  color="red"
                  size="64"
                >
                  mdi-alert-circle
                </v-icon>
                <div class="text-h4 font-weight-bold mt-4">
                  {{ $t('SureTablesCleared') }}
                </div>
                <div class="text-h4 font-weight-bold">
                  {{ $t('CurrentlyOpenTables') }}：{{ unCheckTableName }}
                </div>
                <div class="text-caption">
                  {{ $t('DailyPrintTableHint') }}
                </div>
                <div class="mt-4">
                  <v-btn
                    color="grey"
                    elevation="0"
                    @click="isPrint = false;showPrintWarn = false"
                  >
                    {{ $t('Cancel') }}
                  </v-btn>
                  <v-btn
                    class="ml-4"
                    color="primary lighten-4 black--text"
                    elevation="0"
                    @click="realPrintZbon"
                  >
                    {{ $t('print') }}
                  </v-btn>
                </div>
              </v-card>
            </template>
            <template v-else>
              <v-card
                class="d-flex align-center justify-center flex-column"
                color="white"
                elevation="0"
              >
                <div class="mb-10 text-h3 font-weight-bold">
                  {{ $t('print') }}
                </div>
                <v-progress-circular
                  indeterminate
                  size="64"
                />
              </v-card>
            </template>
          </template>
          <template v-else>
            <v-card
              class="pa-4"
              color="white"
              elevation="0"
            >
              <div class="d-flex align-center"/>
              <div
                class="d-flex align-center justify-center flex-column"
                style="height: 90%"
              >
                <v-icon
                  color="green"
                  x-large
                >
                  mdi-check-circle-outline
                </v-icon>
                <div class="mt-10 text-h3 font-weight-bold">
                  {{ $t('print_success') }}
                </div>
                <div class="text-body-2 text--secondary text-center">
                  {{ $t('need30secondcheckJunk') }}
                </div>
                <v-btn
                  :loading="isLoading"
                  class="mt-6"
                  color="primary lighten-4 black--text"
                  elevation="0"
                  width="50%"
                  @click="backToSelectPrint"
                >
                  {{ $t('Zurück') }}
                </v-btn>
              </div>
            </v-card>
          </template>
        </template>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
  import { predefinedDateRangeDict } from '@/common/Repository/DateRepository'
  import {
    getNiceRestaurantInfo,
    newGetZBon,
    newSetZBon,
    previewZBonPrintTemplate,
    printDeliveryBon,
    printRealTimeSalesBon,
    printSalesBon,
    printSummaryBon,
    sendRealTimeSalesBon,
    sendSalesBon,
    sendSummaryBon,
  } from '@/common/Utlis/api'
  import { parse } from 'node-html-parser'
  import IKPRNode from '@/composable/IKPRNode.vue'
  import SummaryCard from '@/views/AadenPages/dashboard/fragment/widget/SummaryCard.vue'
  import DatePickerInput from '@/views/AadenPages/dashboard/components/DatePickerInput.vue'
  import { loadDashboardData } from '@/model/DashboardService'
  import dayjs from 'dayjs'
  import { today } from 'metaflow-js/EntityFrameWork/DateRepository'
  import LoadingDialog from '@/composable/LoadingDialog'
  import { showSuccessMessage } from '@/common/utils'
  import BackButton from '@/composable/InK/BackButton'
  import TabButton from '@/composable/TabButton'
  import { Remember } from '@/GlobalSettings'
  import i18n from '@/i18n'
  import Table from '@/model/tableAndPerson/Table'
  import { mapMutations } from 'vuex'

  export default {
    name: 'PrintSummaryDialog',
    components: {
      TabButton,
      LoadingDialog,
      DatePickerInput,
      SummaryCard,
      IKPRNode,
      BackButton,

    },
    props: {
      dialogStatus: {},
      value: {},
      dates: {},
    },
    data: function () {
      return {
        isSendEmail: false,
        activeZBon: Remember.activeZBon,
        activeXBon: Remember.activeXBon,
        unCheckTable: [],
        isLoading: false,
        showPrintWarn: false,
        printEnd: false,
        isPrint: false,
        detailTime: [],
        billsCount: '',
        ZBonList: [],
        deliveryList: Remember.deliveryList,
        today,
        sendEmail: Remember.sendEmail,
        mergeBills: Remember.mergeBills,
        totalTime: Remember.totalTime,
        totalSales: Remember.totalSales,
        selectDatesInfo: null,
        realValue: false,
        isPrintMonthZBon: true,
        isPrintMonthDishStatistic: true,
        isSendMonthEmail: true,
        renderedData: null,
        emailOk: null,
        localDates: predefinedDateRangeDict.today.dateRange(),
      }
    },
    computed: {
      disableSendEmail () {
        if (!this.totalTime && !this.totalSales && !this.activeXBon) {
          return false
        } else {
          return true
        }
      },
      disablePrint () {
        if (!this.activeXBon && !this.totalTime && !this.totalSales && !this.deliveryList && !this.activeZBon) {
          return false
        } else {
          return true
        }
      },
      unCheckTableName () {
        return this.unCheckTable.join()
      },
      taxGroupInfo () {
        return this.billContent?.taxInfos?.filter(t => t.consumeTypeName === 'Total')
      },
      billContent () {
        return this.selectDatesInfo?.taxInfos?.content
      },
      statisticData () {
        return this.selectDatesInfo?.dishStatistic ?? []
      },
      drinkItems () {
        return this.statisticData.filter(d => d.categoryTypeId === '9')
      },
      otherItems () {
        return this.statisticData.filter(d => d.categoryTypeId !== '9')
      },
      dishTotal () {
        return this.otherItems.reduce((total, i) => {
          total += parseFloat(i.price) * i.totalCount
          return total
        }, 0)
      },
      drinkTotal () {
        return this.drinkItems.reduce((total, i) => {
          total += parseFloat(i.price) * i.totalCount
          return total
        }, 0)
      },
      totalPaid () {
        return this.selectDatesInfo?.taxInfos?.content?.total ?? 0
      },
      totalOrder () {
        return this.selectDatesInfo?.orderCount ?? 0
      },
      showDetailPrintMessage () {
        let printMessage = ''
        if (!this.mergeBills) {
          if (this.activeZBon) {
            printMessage += i18n.t('DailyStatement') + '(ZBon)' + ' ' + this.billsCount + ' ' + i18n.t('Pice') + '<br>'
          }
          if (this.activeXBon) {
            printMessage += this.$t('PreviewSheet') + '(XBon)' + ' ' + this.billsCount + ' ' + i18n.t('Pice') + '<br>'
          }
          if (this.deliveryList) {
            printMessage += i18n.t('DeliveryList') + ' ' + this.billsCount + ' ' + i18n.t('Pice') + '<br>'
          }
          if (this.totalTime) {
            printMessage += i18n.t('TotalPeriod') + ' ' + this.billsCount + ' ' + i18n.t('Pice') + '<br>'
          }
          if (this.totalSales) {
            printMessage += i18n.t('SalesStatistics') + ' ' + this.billsCount + ' ' + i18n.t('Pice') + '<br>'
          }
        } else {
          if (this.activeXBon) {
            printMessage += this.$t('PreviewSheet') + '(XBon)' + ' ' + 1 + ' ' + i18n.t('Pice') + '<br>'
          }
          if (this.deliveryList) {
            printMessage += i18n.t('DeliveryList') + ' ' + '1' + ' ' + i18n.t('Pice') + '<br>'
          }
          if (this.totalTime) {
            printMessage += i18n.t('TotalPeriod') + ' ' + '1' + ' ' + i18n.t('Pice') + '<br>'
          }
          if (this.totalSales) {
            printMessage += i18n.t('SalesStatistics') + ' ' + '1' + ' ' + i18n.t('Pice') + '<br>'
          }
        }
        return printMessage
      },
      totalBillsCount () {
        let totalBills = 0
        if (!this.mergeBills) {
          if (this.activeZBon) {
            totalBills += 1
          }
          if (this.activeXBon) {
            totalBills += this.billsCount
          }
          if (this.deliveryList) {
            totalBills += this.billsCount
          }
          if (this.totalTime) {
            totalBills += this.billsCount
          }
          if (this.totalSales) {
            totalBills += this.billsCount
          }
        } else {
          if (this.activeXBon) {
            totalBills += 1
          }
          if (this.deliveryList) {
            totalBills += 1
          }
          if (this.totalTime) {
            totalBills += 1
          }
          if (this.totalSales) {
            totalBills += 1
          }
        }
        return totalBills
      },
      dateRangeOnlyToday () {
        return this.localDates.join(',') === predefinedDateRangeDict.today.dateRange().join(',')
      },
      nodes () {
        return this.renderedData ? parse(this.renderedData ?? '') : ''
      },
    },
    watch: {
      dialogStatus (val) {
        if (!val) {
          this.activeZBon = Remember.activeZBon
          this.activeXBon = Remember.activeXBon
          this.deliveryList = Remember.deliveryList
          this.sendEmail = Remember.sendEmail
          this.mergeBills = Remember.mergeBills
          this.totalTime = Remember.totalTime
          this.totalSales = Remember.totalSales
          this.localDates = this.dates
        }
      },
      localDates () {
        this.reload()
      },
      value: {
        immediate: true,
        handler (val) {
          this.realValue = val
        },
      },
      realValue (val, old) {
        if (val !== old) {
          this.$emit('input', val)
        }
        if (!val) {
          this.isPrint = false
          this.printEnd = false
        }
      },
      dates (val) {
        this.localDates = val
      },
    },
    async mounted () {
      await this.reload()
      this.renderedData = await previewZBonPrintTemplate(...this.localDates)
    },
    methods: {
      async selectSendEmail () {
        this.isPrint = true
        Remember.activeZBon = this.activeZBon
        Remember.activeXBon = this.activeXBon
        Remember.sendEmail = this.sendEmail
        Remember.totalSales = this.totalSales
        Remember.totalTime = this.totalTime
        Remember.deliveryList = this.deliveryList
        Remember.mergeBills = this.mergeBills
        const timeSpan = { timespan: this.localDates.join(' - ') }
        if (this.mergeBills) {
          try {
            if (this.activeXBon) {
              await sendSummaryBon(timeSpan)
            }
            if (this.totalTime) {
              await sendRealTimeSalesBon(this.detailTime)
            }
            if (this.totalSales) {
              await sendSalesBon(this.detailTime)
            }
            this.printEnd = true
          } catch (e) {
            this.showErrorDialog(i18n.t('SentFailed'), e?.message)
            this.isPrint = false
            this.printEnd = false
          }
        } else {
          const timeArr = []
          const [startDate, endDate] = this.localDates
          let currentDate = startDate
          while (dayjs(currentDate).isBefore(dayjs(endDate).add(1, 'day'))) {
            timeArr.push([currentDate + ' 04:00:00', dayjs(currentDate).add(1, 'day').format('YYYY-MM-DD') + ' 03:59:59'])
            currentDate = dayjs(currentDate).add(1, 'day').format('YYYY-MM-DD')
          }
          try {
            if (this.activeXBon) {
              await sendSummaryBon(timeSpan)
            }
            for (const it of timeArr) {
              if (this.totalTime) {
                await sendRealTimeSalesBon(it)
              }
              if (this.totalSales) {
                await sendSalesBon(it)
              }
            }
            this.printEnd = true
          } catch (e) {
            this.showErrorDialog(i18n.t('SentFailed'), e?.message)
            this.isPrint = false
            this.printEnd = false
          }
        }
      },
      selectXBon () {
        this.activeXBon = !this.activeXBon
        if (this.activeXBon) {
          this.activeZBon = false
        }
      },
      selectZBon () {
        this.activeZBon = !this.activeZBon
        if (this.activeZBon) {
          this.activeXBon = false
        }
      },
      checkZBonEmail () {
        setTimeout(() => {
          if (!this.emailOk) {
            this.showErrorDialog(i18n.t('SetupZBonFirst'))
            this.sendEmail = false
          }
        }, 300)
      },
      ...mapMutations(['showErrorDialog']),
      async backToSelectPrint () {
        this.isLoading = true
        await this.reload()
        this.isLoading = false
        this.showPrintWarn = false
        this.printEnd = false
        this.isPrint = false
        this.isLoading = false
      },
      async closeDialog () {
        this.$emit('close-dialog')
        await this.reload()
      },
      async reload () {
        this.emailOk = (await getNiceRestaurantInfo()).ZBonEmail
        if (this.sendEmail) {
          if (!this.emailOk) {
            this.sendEmail = false
          }
        }
        const fromTime = this.localDates[0] + ' 04:00:00'
        const ZbonFromTime = dayjs(this.localDates[0]).subtract(1, 'day').format('YYYY-MM-DD') + ' 04:00:01'
        const toTime = dayjs(this.localDates[1]).add(1, 'day').format('YYYY-MM-DD') + ' 03:59:59'
        this.detailTime = [fromTime, toTime]
        const ZbonPrintTime = [ZbonFromTime, toTime]
        this.ZBonList = (await newGetZBon(ZbonPrintTime)).map(it => it.zbonNumber)
        if (this.ZBonList.length !== 0) {
          this.activeZBon = false
        }
        this.billsCount = dayjs(this.localDates[1]).add(1, 'day').diff(dayjs(this.localDates[0]), 'day')
        this.selectDatesInfo = await loadDashboardData(this.localDates)
      },
      async printZBonWarn () {
        this.unCheckTable = ((await Table.getList(true)).filter(x => x.usageStatus === '1')).map(it => it.name)
        Remember.activeZBon = this.activeZBon
        Remember.activeXBon = this.activeXBon
        Remember.sendEmail = this.sendEmail
        Remember.totalSales = this.totalSales
        Remember.totalTime = this.totalTime
        Remember.deliveryList = this.deliveryList
        Remember.mergeBills = this.mergeBills
        this.isPrint = true
        if (this.unCheckTable.length !== 0) {
          this.showPrintWarn = true
        } else {
          await this.realPrintZbon()
        }
      },
      async realPrintZbon () {
        try {
          if (this.sendEmail) {
            await newSetZBon({ allowSameDay: 0 }, 1)
          } else {
            await newSetZBon({ allowSameDay: 0 }, 0)
          }
          await this.ZbonPrintSelectedOptions(this.detailTime)
          showSuccessMessage(i18n.t('print_success'))
          this.printEnd = true
          await this.reload()
        } catch (e) {
          this.showErrorDialog(i18n.t('SentFailed'), e?.message)
          this.isPrint = false
        }
      },
      selectPrintType () {
        if (this.activeZBon) {
          this.printZBonWarn()
        } else {
          this.printBills()
        }
      },
      async printBills () {
        Remember.activeZBon = this.activeZBon
        Remember.activeXBon = this.activeXBon
        Remember.sendEmail = this.sendEmail
        Remember.totalSales = this.totalSales
        Remember.totalTime = this.totalTime
        Remember.deliveryList = this.deliveryList
        Remember.mergeBills = this.mergeBills
        this.isPrint = true
        if (this.mergeBills) {
          await this.printSelectedOptions(this.detailTime)
        } else {
          const timeArr = []
          const [startDate, endDate] = this.localDates
          let currentDate = startDate
          while (dayjs(currentDate).isBefore(dayjs(endDate).add(1, 'day'))) {
            timeArr.push([currentDate + ' 04:00:00', dayjs(currentDate).add(1, 'day').format('YYYY-MM-DD') + ' 03:59:59'])
            currentDate = dayjs(currentDate).add(1, 'day').format('YYYY-MM-DD')
          }
          try {
            for (const it of timeArr) {
              await this.printSelectedOptions(it)
            }
          } catch (e) {
            this.isPrint = false
            this.printEnd = false
            this.showErrorDialog(i18n.t('failed_to_print'), e?.message)
          }
        }
        showSuccessMessage(i18n.t('print_success'))
        this.printEnd = true
      },
      async ZbonPrintSelectedOptions (timeArray) {
        if (this.totalSales) {
          await this.selectSales(timeArray)
        }
        if (this.totalTime) {
          await this.selectTime(timeArray)
        }
        if (this.deliveryList) {
          await printDeliveryBon(timeArray)
        }
      },
      async printSelectedOptions (timeArray) {
        if (this.activeXBon) {
          const printFromTime = dayjs(timeArray[0]).format('YYYY-MM-DD')
          const printToTime = dayjs(timeArray[1]).subtract(1, 'day').format('YYYY-MM-DD')
          const printTime = [printFromTime, printToTime]
          const timeSpan = { timespan: printTime.join(' - ') }
          await printSummaryBon(timeSpan)
          await sendSummaryBon(timeSpan)
        }
        if (this.totalSales) {
          await this.selectSales(timeArray)
        }
        if (this.totalTime) {
          await this.selectTime(timeArray)
        }
        if (this.deliveryList) {
          await printDeliveryBon(timeArray)
        }
      },
      async selectTime (timeArray) {
        if (this.sendEmail) {
          await printRealTimeSalesBon(timeArray)
          await sendRealTimeSalesBon(timeArray)
        } else {
          await printRealTimeSalesBon(timeArray)
        }
      },
      async selectSales (timeArray) {
        if (this.sendEmail) {
          await printSalesBon(timeArray)
          await sendSalesBon(timeArray)
        } else {
          await printSalesBon(timeArray)
        }
      },
    },
  }
</script>

<style scoped>

</style>
