<template>
  <v-card
    :dark="dark"
    :color="color"
    elevation="0"
    class="pa-4"
  >
    <div
      class="text-body-2"
    >
      {{ label }}
    </div>
    <div class="d-flex align-center mt-8">
      <v-icon size="36">
        {{ icon }}
      </v-icon>
      <v-spacer/>
      <div class="text-h3 font-weight-bold ">
        {{ value }}
      </div>
    </div>
  </v-card>
</template>

<script>
  export default {
    name: 'SummaryCard',
    props: {
      dark: {
        type: Boolean,
        default: false,
      },
      label: {},
      icon: {},
      value: {},
      color: {},
    },
  }
</script>

<style scoped>

</style>
